"use client";

import { metadata, type AITool } from "@app/_metadata";
import { ActiveFeatureFlags } from "@shared-components/global/active-feature-flags";
import { Ident } from "@shared-components/global/ident";
import { TopBarMenu } from "@shared-components/global/top-bar-menu";
import { UserDropdown } from "@shared-components/global/user-dropdown";
import { Trophy } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";

interface TopBarProps {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  aiTools: AITool[];
}

/**
 * Topbar
 *
 * @param props TopBarProps
 */
export const TopBar = (props: TopBarProps) => {
  const path = usePathname();
  // Find active tool
  const activeAiTool = metadata.aiTools?.find((appMeta) =>
    path.includes(appMeta.slug),
  );
  /**
   * Render
   */
  return (
    <>
      <div className="fixed left-0 top-0 z-50 flex h-14 w-full flex-row items-center justify-between border-b border-border bg-background px-4">
        <div className="flex flex-row items-center">
          <Link href={"/"}>
            <Ident aiTool={activeAiTool} />
          </Link>
          <span className="hidden md:block">{props.leftChildren}</span>
        </div>
        <div className="flex flex-row items-center">
          <a
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=YnOP1yyDFUeOEsx71XQUTPwoY2VjKM9AhtO3_vEfra5UN0tHQlo5MlJKSUdOTDJBQ1FGWDdKS0UzSy4u"
            target="_blank"
            className="hidden flex-row items-center gap-2 rounded-full border border-leo-sunflower-600 bg-leo-sunflower-300 px-4 py-1 transition-colors hover:bg-leo-sunflower-400 md:flex"
          >
            <Trophy size={20} />
            <span>
              <span>{"Submit your entry to "}</span>
              <span className="font-bold">{"LEO AI Awards"}</span>
              <span>{" here"}</span>
            </span>
          </a>
          <ActiveFeatureFlags />
          <span className="hidden md:block">{props.rightChildren}</span>
          <UserDropdown />
          <TopBarMenu activeAiTool={activeAiTool!} />
        </div>
      </div>
    </>
  );
};
