// Supported feature flags
export const FeatureFlag = [
  "TRANSLATE_AI__GLOSSARIES__185407",
  "CHAT_AI__CLINICAL_TRALS_GOV__11697",
  "CHAT_AI__WEB_READER__187473",
  "ISEARCH__APP__188825",
] as const;
export type FeatureFlagType = (typeof FeatureFlag)[number];

// Map to print feature flags
export const featuresMap: { [key in FeatureFlagType]: string } = {
  TRANSLATE_AI__GLOSSARIES__185407: "Translate AI Glossaries",
  CHAT_AI__CLINICAL_TRALS_GOV__11697: "Chat AI Plugin Clinical Trials Gov",
  CHAT_AI__WEB_READER__187473: "Chat AI Plugin Web Reader",
  ISEARCH__APP__188825: "iSearch",
};
